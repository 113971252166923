<template>
   <ObiCard class="h-100">
      <template #header>
         <ObiTitle :title="title">
            <template #actions>
               <ObiSwitch v-model="inputValue.is_active" />
            </template>
         </ObiTitle>
         <ObiText> {{ content }} </ObiText>
      </template>
      <ObiInput
         :disabled="!inputValue.is_active"
         outside
         v-model="inputValue.value"
         placeholder="Günlük birim fiyat giriniz"
      />
      <div class="d-flex justify-content-between align-items-center p-2">
         <ObiText class="obi-option-value">{{ getFormattedValue }}</ObiText>
         <ObiButtonSwitch
            :items="options"
            :asObject="true"
            v-model="inputValue.option"
            :disabled="!inputValue.is_active"
         />
      </div>
   </ObiCard>
</template>

<script>
import { keys, omit } from 'lodash';
import 'vue-range-slider/dist/vue-range-slider.css';

export default {
   name: 'ObiPaidSale',
   components: {},
   props: {
      value: {},
      title: {
         type: String,
         required: true,
      },
      content: {
         type: String,
         default: '',
      },
      options: {
         type: Array,
         required: true,
      },
   },
   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
      optionTitle() {
         return this.time_option;
      },
      inputValue: {
         get() {
            return this.value;
         },
         set(val) {
            this.$emit('input', val);
         },
      },
      getFormattedValue() {
         if (!this.inputValue.is_active) {
            return 'Ücretsiz';
         }
         if (this.inputValue && this.inputValue.value && this.inputValue.option) {
            return `${this.inputValue.value} ${this.inputValue.option.label}`;
         }

         return 'Lütfen Seçin';
      },
   },
   data() {
      return {
         selected: {
            value: 0,
            option: {},
         },
      };
   },
};
</script>

<style lang="scss" scoped>
.obi-slider {
   width: 100%;
}
.obi-option-value {
   padding: 10px;
   background: #ffffff;
   border: 1px solid #2f71f2;
   box-sizing: border-box;
   border-radius: 3px;
   font-style: normal;
   font-weight: bold;
   font-size: 15px;
   line-height: 18px;
   color: #2f71f2;
}
</style>
